<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            type="search"
            :comboItems="comboItems1"
            itemText="codeName"
            itemValue="code"
            name="inoutType"
            label="입출고 구분"
            v-model="searchParam.inoutType"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            default="today"
            label="입출고 기간"
            name="inoutDts"
            v-model="searchParam.inoutDts"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-material
            type="search"
            label="자재"
            name="materialCd"
            :plantCd="searchParam.plantCd"
            v-model="searchParam.materialCd">
          </c-material>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="자재 입출고 이력"
      :columns="grid.columns"
      :merge="grid.merge"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'outernal-manage',
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      comboItems1: [
        {
          code: '입고',
          codeName: '입고',
        },
        {
          code: '출고',
          codeName: '출고',
        },
      ],
      searchParam: {
        plantCd: null,
        inoutType: null,
        inoutDts: [],
        materialCd: '',
      },
      grid: {
        merge:  [
            { index: 0, colName: "materialCd" },
            { index: 1, colName: "materialCd" },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'materialName',
            field: 'materialName',
            label: '자재명',
            align: 'left',
            sortable: true,
            type: 'link'
          },
          {
            name: 'inoutType',
            field: 'inoutType',
            label: '입/출고 구분',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'inoutDt',
            field: 'inoutDt',
            label: '입/출고일',
            align: 'center',
            style: 'width:300px',
            sortable: true,
          },
          {
            name: 'inoutStock',
            field: 'inoutStock',
            label: '입/출고량',
            type: 'cost',
            style: 'width:300px',
            sortable: true,
          },
          {
            name: 'materialUnitName',
            field: 'materialUnitName',
            label: '단위',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sp.materialinout.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row){
      this.popupOptions.title = '정비자재 마스터 상세';
      this.popupOptions.param = {
        plantCd: row ? row.plantCd : '',
        materialCd: row ? row.materialCd : '',
      };
      this.popupOptions.target = () => import(`${'./spareDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
